.subsite {
    margin-top: 100px;
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        top: 0;
        position: fixed;
        width: 100%;  
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #fff;
        h1 {
            margin-bottom: 80px;
        }
    }
}
