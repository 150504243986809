.news {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    &__title {
        margin: 0;
    }
    &__summary {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        &--single {
            background-color: #fff;
            padding: 10px 20px;
            border-radius: 10px;
            display: flex;
            column-gap: 10px;
        }
        &.block .news__summary--single {
            flex-direction: column;
            align-items: center;
        }
    }
    &__date {

    }
    &__categories {

        &--single {
            span {
                background-color: $color-main;
                padding: 5px 10px;
                border-radius: 5px;
                color: #fff;
                text-transform: uppercase;
                font-size: 13px;
            }
        }
    }
    &__author {

    }
    &__source {

    }
    &__icon {
        svg {
            width: 20px;
            height: auto;
        }
    }
    &__label {
        font-weight: 600;
    }
}
