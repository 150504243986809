

.table {
    display: flex;
    width: 100%;
    margin-top: 60px;
    &-wrapper {
        display: flex;
        flex-direction: column;
    }
    &-desription {
        display: flex;
        flex-direction: column;
        flex-basis: 32%;
        p, span {
            margin: 0;
            height: auto;
            min-height: 65px;
            display: flex;
            align-items: center;
            padding: 0 13px 0 13px;
            justify-content: flex-start;
            @include mq($until: xl){
                font-size: rem(14px);
            }
        }
        span {
            font-weight: 700;
        }
        p:nth-child(odd) {
            background-color: $color-body;
        }
    }
    &-content {
        transition: ease 0.3s all;
        flex-basis: 17%;
        &:hover {
            box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.55);
            z-index: 1;
        }
        &.lite {
            span {
                background-color: #ffd800; 
            }
        }
        &.apartment  {
            span {
                background-color: #ffbb19; 
            }
        }
        &.operator {
            span {
                background-color: #fe7102; 
            }
        }
        &.hotel {
            span {
                background-color: #f14317; 
            }
        }
        p {
            background-color: #fff;
            &:nth-child(even) {
                background-color: $color-body;
            }
        }
        span {
            font-weight: 600;
            color: #fff;
            line-height: normal;
            @include mq($until: xl){
                &:last-of-type {
                    flex-direction: column;
                    padding: 10px;
                }
            }
            a {
                padding: 8px 13px 8px 13px;
                font-weight: 400;
                background-color: #fff;
                border-radius: 3px;
                opacity: 1;
                margin-left: 3px;
                transition: opacity 0.4s ease-out;
                color: $paragraph-color;
                @include mq($until: xl){
                    margin-top: 5px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        p, span {
            margin: 0;
            height: auto;
            min-height: 65px;
            display: flex;
            align-items: center;
            padding: 0 13px 0 13px;
            justify-content: center;
            text-align: center;
            @include mq($until: xl){
                font-size: rem(14px);
            }
        }
    }
    &-mobile {
        margin-top: 60px;
        h3 {
            font-size: rem(16px);
        }
        .table-group {
            margin-bottom: 40px;
            &.price {
                .table-content {
                    flex-direction: column;
                }
                span {
                    flex-basis: unset;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
        .table-content {
            display: flex;
            width: 100%;
            margin-top: 1px;
            span {
                flex-basis: 40%;
                align-items: flex-start;
                text-align: left;
                @include mq($until: 380px){
                    flex-basis: 50%;
                }
            }
            p {
                flex-basis: 60%;
                @include mq($until: 380px){
                    flex-basis: 50%;
                }
            }
        }
    }
}


