.leaflet-container {
    height: 80vh;
    z-index: 0;
    .leaflet-marker-icon svg path[fill="#2d3e72"] {
        transition: ease 0.3s all;
    }
    .leaflet-marker-icon svg:hover path[fill="#2d3e72"] {
        fill: $color-main;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: 12px 12px 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: 12px 12px 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            color: #fff;
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content {
        margin: 0;
    }
    .leaflet-tooltip {
        padding: 6px 10px;
        background-color: $color-main;
        border: unset;
        border-radius: 5px;
        color: #fff;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
    }
    .leaflet-tooltip-top:before {
        border-top-color: $color-main;
    }
    .overlay {
        z-index: 401;
        mix-blend-mode: color;
    }
}
