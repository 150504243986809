.about {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background-color: #fff;
    @include mq($until: md){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    &__content {
        position: relative;
        z-index: 1;
        .row {
            margin-bottom: 40px;
            column-gap: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            @include mq($until: xl){
                column-gap: 40px;
            }
            @include mq($until: md){
                grid-template-columns: 1fr;
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            .col-lg-6 {
                max-width: 100%;
                width: 100%;
                margin-bottom: 20px;
                @include mq($until: md){
                    margin-bottom: 40px;
                }
                &:hover {
                    h2 {
                        color: $color-extra;
                    }
                }
            }
        }
        h2 {
            @include mq($until: md){
                font-size: rem(24px);
            }
            @include mq($until: sm){
                font-size: rem(20px);
            }
        }
        div h2 {
            font-size: rem(24px);
            font-weight: 600;
            margin-bottom: 20px;
            margin-top: 0;
            transition: ease 0.3s all;
            display: flex;
            align-items: center;
            @include mq($until: 1820px){
                font-size: rem(20px);
            }
            @include mq($until: md){
                font-size: rem(18px);
            }
            @include mq($until: xs){
                flex-direction: column;
                text-align: center;
            }
            &:before {
                content: '';
                width: 40px;
                height: 40px;
                background: url('/themes/default/assets/images/favicon.webp');
                background-position: center;
                background-size: contain;
                margin-right: 5px;
                min-width: 50px;
                @include mq($until: xs){
                    float: unset;
                    margin: 0 auto 10px auto;
                }
            }
        }
        .icons-set {
            margin-top: 40px;
            margin-bottom: 40px;
            row-gap: 80px;
            column-gap: 80px;
            @include mq($until: xl){
                column-gap: 40px;
            }
            @include mq($until: md){
                grid-template-columns: repeat(2, 1fr);
            }
            @include mq($until: xs){
                grid-template-columns: 1fr;
                row-gap: 40px;
                margin-top: 0;
            }
            &__item {
                &:hover {
                    svg {
                        fill: $color-extra;
                    }
                }
                svg {
                    width: 140px;
                    height: auto;
                    fill: #bdbbbc;
                    transition: ease 0.3s all;
                    @include mq($until: xl){
                        width: 100px;
                    }
                }
                @include mq($until: xl){
                    .icons-set__item-text {
                        font-size: rem(14px);
                    }
                }
            }
        }
        .faq {
            margin-top: 60px;
            @include mq($until: md){
                grid-template-columns: 1fr;
            }
            &__question-list .single {
                padding: 10px 15px 10px 15px;
                border: 1px solid $border-color;
            }
            &-icons.left svg {
                margin-right: 15px;
            }
            &--separate .faq__question-list {
                row-gap: 20px;
            }
            span {
                transition: ease 0.3s all;
            }
        }
    }
    &.cta {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 0;
        .about__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 10px;
            p {
                font-size: rem(26px);
                font-weight: 600;
                text-align: center;
                margin-bottom: 0;
                span {
                    color: $color-main;
                    font-size: rem(28px);
                    font-weight: 700;
                    text-transform: uppercase;    
                    &:nth-child(2) {
                        color: adjust-hue($color-main, 350deg);
                    }
                    &:nth-child(3) {
                        color: adjust-hue($color-main, 340deg);
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                column-gap: 20px;
                font-size: rem(28px);
                font-weight: 700;
                color: $paragraph-color;
                margin-top: 60px;
                &:hover {
                    color: $color-main;
                }
                svg {
                    width: 60px;
                    height: auto;
                    fill: $color-main;
                    transition: ease 0.3s all;
                }
            }
        }
        .about__image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: -200px;  
            z-index: 2;
            position: relative;  
            img:first-of-type {
                margin-right: 16vw;
            }
        }
    }
}
