.top {
    position: relative;
}
.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 120px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 100px;
    @include mq($until: lg){
        min-height: unset;
        aspect-ratio: 4/3;
        padding-bottom: 140px;
    }
    @include mq($until: md){
        padding-bottom: 160px;
    }
    @include mq($until: sm){
        padding-bottom: 0;
    }
    @include mq($until: xs){
        aspect-ratio: 1/1;
    }
    &--small {
        min-height: unset;
        height: 348px;
        background-size: cover;
        .hero__content h1 {
            font-size: rem(28px);
        }
        @include mq($until: lg){
            .hero__content {
                h1 {
                    font-size: rem(38px);
                }
            }
        }
        @include mq($until: sm){
            .hero__content {
                h1 {
                    font-size: rem(28px);
                }
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        h1 span, h1 {
            color: #fff;
            margin-bottom: 0;
            font-size: 3.5vw;
            line-height: 3.5vw;
            font-weight: 700;
            display: block;
            text-shadow: 10px 10px 20px #191c2c;
            @include mq($until: sm){
                font-size: 4.5vw;
                line-height: 4.5vw;
            }
            &:nth-child(2) {
                font-size: 2.9vw;
                @include mq($until: sm){
                    font-size: 3.9vw;
                }
            }
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type {
                margin-top: 20px;
                @include mq($until: sm){
                    margin-top: 0;
                }
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
            @include mq($until: lg){
                font-size: rem(20px);
            }
            @include mq($until: md){
                padding: 20px 20px;
            }
            @include mq($until: sm){
                padding: 15px 20px;
                font-size: rem(16px);
            }
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
    &__image {
        max-width: 40%;
        @include mq($until: sm){
            display: none;
        }
        img {
            max-width: 604px;
            width: 100%;
            height: auto;
            max-height: 50vh;
        }
    }
    &__links {
        position: absolute;
        left: 0;
        bottom: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: end;
        width: 100%;
        @include mq($until: md){
            grid-template-columns: 1fr 1fr;
        }
        @include mq($until: sm){
            position: relative;
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
        a {
            padding: 20px 30px;
            background-color: $color-main;
            color: #fff;
            transition: ease 0.3s all;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 20px;
            min-height: 120px;
            @include mq($until: xxl){
                justify-content: space-between;
            }
            @include mq($until: xl){
                padding: 20px;
                min-height: 100px;
            }
            @include mq($until: lg){
                flex-direction: column-reverse;
                row-gap: 20px;
                text-align: center;
            }
            @include mq($until: md){
                flex-direction: row;
                text-align: unset;
                min-height: unset;
            }
            &:hover {
                background-color: adjust-hue($color-main, 355deg);
                padding: 20px 30px 40px 30px;
                @include mq($until: xl){
                    padding: 20px 20px 40px 20px;
                }
                @include mq($until: md){
                    padding: 20px;
                }
                svg {
                    opacity: 1;
                }
            }
            &:nth-child(2) {
                background-color: adjust-hue($color-main, 350deg);
                &:hover {
                    background-color: adjust-hue($color-main, 345deg);
                }
            }
            &:nth-child(3) {
                background-color: adjust-hue($color-main, 340deg);
                &:hover {
                    background-color: adjust-hue($color-main, 335deg);
                }
            }
            &:nth-child(4) {
                background-color: adjust-hue($color-main, 330deg);
                &:hover {
                    background-color: adjust-hue($color-main, 325deg);
                }
            }
            span {
                color: #fff;
                font-weight: 700;
                font-size: rem(21px);
                line-height: rem(23px);
                @include mq($until: xxl){
                    font-size: rem(18px);
                }
                @include mq($until: xl){
                    font-size: rem(15px);
                }
            }
            svg {
                fill: #fff;
                width: 80px;
                height: auto;
                opacity: 0.4;
                transition: ease 0.3s all;
                @include mq($until: xl){
                    width: 40px;
                }
            }
        }
    }
    .divider[data-divider*="gradient"]:before {
        background: linear-gradient(0deg, rgba(25, 28, 44, 0) 0%, rgba(25, 28, 44, 0.9) 100%);
    }
}
