.info {
    padding-top: 200px;
    padding-bottom: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: calc(100vh - 40px);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    @include mq($until: xxl){
        min-height: 51vw;
        background-size: contain;
        background-position: bottom;
        background-color: #e8e8e8;
        padding-top: 160px;
        padding-bottom: 40px;
    }
    &--first {
        padding-top: 100px;
        padding-bottom: 200px;
        background-size: auto  100%;
        background-position: center left;
        background-color: #e5e5e5;
        @include mq($until: xxl){
            min-height: 51vw;
            background-size: contain;
            background-position: unset;
            padding-top: 40px;
            padding-bottom: 120px;
            .info__content {
                max-width: 40vw;
            }
        }
        @include mq($until: lg){
            min-height: unset;
            background-size: 100vw;
            background-position: unset;
            padding-top: 46vw;
            padding-bottom: 140px;
            .info__content {
                max-width: 100%;
                h2 {
                    position: absolute;
                    right: 0;
                    top: -35vw;
                    max-width: 50%;
                }
            }
        }
        @include mq($until: sm){
            min-height: unset;
            background-size: 200vw;
            background-position: unset;
            padding-top: 90vw;
            padding-bottom: 140px;
            .info__content h2 {
                position: relative;
                right: 0;
                top: unset;
                max-width: 100%;
            }
        }
        @include mq($until: xs){
            padding-bottom: 40px;
        }
    }
    &--second {
        @include mq($until: 1300px){
            padding-bottom: 90vw;
            padding-top: 140px;
            background-position: top;

            .info__content {
                max-width: 100%;
                h2 {
                    position: absolute;
                    right: 0;
                    top: -35vw;
                    max-width: 50%;
                }
            }
        }
    }
    &__content {
        max-width: 32vw;
        h2 {
            margin-bottom: 40px;
            @include mq($until: xxl){
                font-size: rem(26px);
            }
            @include mq($until: xl){
                font-size: rem(22px);
                margin-bottom: 20px;
            }
            @include mq($until: sm){
                font-size: rem(20px);
            }
        }
        p {
            margin-bottom: 40px;
            @include mq($until: xl){
                margin-bottom: 20px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__top {
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        z-index: 1;
        @include mq($until: xs){
            position: relative;
            grid-template-columns: 1fr;
            text-align: center;
            background-color: #fff;
            padding-bottom: 40px;
        }
        p {
            background-color: #fff;
            padding-top: 30px;
            padding-bottom: 30px;
            position: relative;
            @include mq($until: lg){
                font-size: rem(16px);
            }
            @include mq($until: xs){
                padding-right: 20px;
            }
            &:after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                height: 100%;
                aspect-ratio: 1/1;
                background-image: url('/themes/default/assets/images/shape.svg');
                background-position: right;
                background-size: 100%;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                @include mq($until: xs){
                    display: none;
                }
        
            }
            strong {
                color: $color-main;
                font-weight: 700;
                @include mq($until: xs){
                    color: $paragraph-color;
                    display: block;
                    font-size: rem(20px);
                    margin-bottom: 20px;
                }
            }
        }
        .portals {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding-left: 80px;
            @include mq($until: xl){
                justify-content: center;
            }
            @include mq($until: md){
                flex-direction: column;
                padding-top: 10px;
            }
            @include mq($until: xs){
                padding-left: 0;
                row-gap: 10px;
            }
            a {
                @include mq($until: xl){
                    max-width: 30%;
                }    
                @include mq($until: md){
                    max-width: 40%;
                }
                @include mq($until: sm){
                    max-width: 55%;
                }
                &:first-of-type {
                    margin-right: 20px;
                    @include mq($until: md){
                        margin-right: 0;
                    }    
                }
            }
            img {
                height: auto;
            }
        }
    }
    &__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        z-index: 1;
        @include mq($until: xs){
            position: relative;
            grid-template-columns: 1fr;
            text-align: center;
            background-color: #fff;
        }
        h2 {
            padding-top: 30px;
            padding-bottom: 30px;
            margin: 0;
            @include mq($until: xl){
                font-size: rem(24px);
            }
            @include mq($until: md){
                font-size: rem(20px);
            }
            @include mq($until: xs){
                padding-top: 60px;
                padding-right: 20px;
                order: 1;
            }
        }
        .contact {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 30px;
            position: relative;
            @include mq($until: xl){
                justify-content: center;
            }
            @include mq($until: lg){
                column-gap: 20px;
            }
            @include mq($until: md){
                flex-direction: column;
            }    
            &:after {
                content: '';
                position: absolute;
                right: 100%;
                top: 0;
                height: 100%;
                aspect-ratio: 1/1;
                background-image: url('/themes/default/assets/images/shape.svg');
                background-position: right;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            a {
                display: flex;
                align-items: center;
                column-gap: 20px;
                font-size: rem(22px);
                font-weight: 600;
                color: $paragraph-color;
                @include mq($until: xl){
                    font-size: rem(20px);
                }
                @include mq($until: lg){
                    font-size: rem(17px);
                    column-gap: 15px;
                }
                @include mq($until: xs){
                    padding: 20px;
                    background-color: $color-main;
                    width: 100%;
                    justify-content: center;
                    color: #fff;
                    &:nth-child(2) {
                        background-color: adjust-hue($color-main, 350deg);
                        &:hover {
                            background-color: adjust-hue($color-main, 345deg);
                        }
                    }
                }
                &:hover {
                    color: $color-main;
                    @include mq($until: xs){
                        color: #fff;
                        background-color: adjust-hue($color-main, 355deg);
                    }
                }
                svg {
                    width: 40px;
                    height: auto;
                    fill: $color-main;
                    transition: ease 0.3s all;
                    @include mq($until: lg){
                        width: 30px;
                    }
                    @include mq($until: xs){
                        fill: #fff;
                    }
                }
            }
        }
    }
}
