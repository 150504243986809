.realizations {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    @include mq($until: lg){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            font-weight: 300;
            @include mq($until: md){
                font-size: rem(24px);
            }
            @include mq($until: sm){
                font-size: rem(20px);
            }
        }
        p {
            text-align: center;
        }
    }
    .gallery {
        margin-top: 80px;
        @include mq($until: lg){
            margin-top: 60px;
        }
        @include mq($until: sm){
            grid-template-columns: 1fr;
            padding: 0 30px;
            grid-gap: 30px;
        }
        @include mq($until: xs){
            padding: 0 20px;
            grid-gap: 20px;
        }
        .image {
            height: 80vh;
            background-color: transparent;
            @include mq($until: md){
                height: 50vh;
            }
            @include mq($until: sm){
                height: unset;
                aspect-ratio: 4/3;
            }
        }
        .icon span {
            border: 1px solid #fff;
            padding: 10px 20px;
            border-radius: 10px;
        }
        &__link {
            overflow: hidden;
            position: relative;
        
            &:hover img {
                transform: translate(0, -700px);
                @include mq($until: xl){
                    transform: translate(0, -280px);
                }
                @include mq($until: lg){
                    transform: translate(0, -140px);
                }
                @include mq($until: sm){
                    transform: translate(0, -500px);
                }
                @include mq($until: sm){
                    transform: translate(0, -600px);
                }
                @include mq($until: xs){
                    transform: translate(0, -440px);
                }
            }
            img {
                transition: all 2s ease-in-out;
            }
        }
    }
}
